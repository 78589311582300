import React from 'react';

export const initialPlaylist = {
    token: null,
    id: null,
    settings: {
        blockedGenres: [],
        allowExplicit: true
    }
}

export const PlaylistContext = React.createContext(initialPlaylist);