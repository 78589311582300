import React from 'react';
import _ from 'lodash';

const primaryColor = '#00B0F0';
const secondaryColor = 'rgb(187,213,236)';

export class Music extends React.Component{

    shouldComponentUpdate(nextProps, nextState){
        if(!_.isEqual(nextProps.song, this.props.song)){
            return true;
        }
        return false;
    }

    renderAvatar(){
        const { song } = this.props;
        const addedBy = song ? song.addedBy || {} : {};
        let url = '';
        if(addedBy.photoURL){
            url = addedBy.photoURL
        } else {
            const source = song.source || {};
            const album = source.album || {};
            const images = album.images || [];
            url = images[0].url;
        }
        return(
            <img 
                src={url} 
                width="50" 
                height="50"
                border="1" 
                style={{
                    borderRadius: 25,
                    borderWidth: 2,
                    borderColor: primaryColor
                }}
            />
        )
    }

    render(){
        const { playing, song } = this.props;
        if(!song || song === null) return (<></>);
        const source = song.source || {};
        const album = source.album || {};
        const artists = album.artists ? album.artists.map(artist => {
            return artist.name
        }) : source.artists ? source.artists.map(artist => {
            return artist.name
        }) : [];
        return(
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center'
            }}>
                {!playing &&
                    <div>
                        {this.renderAvatar()}
                    </div>
                }
                <div style={{marginLeft: 20, marginRight: 20}}>
                    {playing ?
                        <h3 style={{color: '#fff'}}>{source.name}</h3> :
                        <h4 style={{color: '#fff'}}>{source.name}</h4>
                    }
                    <span style={{color: secondaryColor}}>{artists.join(', ')}</span>
                </div>
                {playing &&
                    <div>
                        {this.renderAvatar()}
                    </div>
                }
            </div>
        )
    }

}