import React from 'react';
import { Form, Dropdown, Spinner } from 'react-bootstrap';
import { Search } from '../service/spotify/Search';

export default class PlaylistSearch extends React.PureComponent{

    constructor(props,state){
        super(props,state);
        this.state = {
            loading: false,
            showDropdown: false,
            finished: false,
            term: '',
            limit: 10,
            paged: 0,
            items: []
        }
        this.service = new Search(this.props.token);
        this.dropdown = React.createRef();
    }

    componentDidUpdate(prevProps){
        if(prevProps.token != this.props.token);
        this.service.setToken(this.props.token);
    }

    changeTerm(e){
        this.setState({
            term: e.target.value
        })
    }

    searchForPlaylist(e){
        e.preventDefault();
        if(this.state.term.length > 0){
            if(this.state.loading) return;
            if(this.dropdown.current){
                if(!this.dropdown.current.props.show){
                    this.dropdown.current.handleClose()
                }
            }                
            this.setState({
                paged: 0,
                finished: false,
                items: []
            },() => {
                this.loadItems()
            })
            
        } else {
            this.setState({
                termError: true
            })
        }
    }

    loadItems(){  
        const { loading, finished, term, limit, paged } = this.state;
        if(loading || finished) return;
        this.setState({
            loading: true
        },() => {
            this.service.searchPlaylist(term, limit, paged).then( result => {
                if(result.playlists && result.playlists.items){
                    const items = this.state.items.slice().concat(result.playlists.items);
                    this.setState({
                        items,
                        loading: false,
                        paged: this.state.paged + 1,
                        finished: result.playlists.items.length < this.state.limit
                    })
                } else {
                    this.setState({
                        loading: false,
                        finished: true
                    })
                }
            }).catch(e => {
                console.log(e);
                this.setState({
                    loading: false,
                    finished: true
                })
            })
        })
        
    }

    handleItemClick = (item) =>{
        this.setState({
            items: []
        })
        this.props.onSelect(item)
    }

    render(){
        return(
            <div style={{
                flexDirection: 'row',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flex: 2
            }}>
                <Form method={'post'} style={{width: '100%'}} onSubmit={this.searchForPlaylist.bind(this)}>
                    <Form.Control 
                        type={'text'}
                        name={'term'}
                        placeholder={'Pesquisar playlist para tocar em segundo plano'}
                        value={this.state.term}
                        onSubmit={this.searchForPlaylist.bind(this)}
                        onChange={this.changeTerm.bind(this)}
                        autoComplete={'off'}  
                    />
                    <Dropdown style={{
                            backgroundColor: '#262626',
                            position: 'absolute'
                        }} >
                            {this.state.loading &&
                                <Dropdown.Item><Spinner animation="grow" variant="primary" /></Dropdown.Item>
                            }
                            {this.state.items.map(item => {
                                const image = item.images ? item.images[item.images.length-1] : undefined;
                                return(
                                    <Dropdown.Item key={item.id} onClick={() => { this.handleItemClick(item) }}>
                                        <div
                                            style={{
                                                flexDirection: 'row',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'flex-start'
                                            }}
                                        >
                                        {image &&
                                            <img src={image.url} style={{width: 50, height: 50}} />
                                        }
                                        <span style={{color: '#fff', marginLeft: 20 }}>{item.name}</span>
                                        </div>
                                    </Dropdown.Item>
                                )
                            })}
                    </Dropdown>
                </Form>
            </div>
        )
    }
}