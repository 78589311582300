import React from 'react';
import PlaylistView from './View/PlaylistView';

function App() {
  return (
    <div style={{ flex: 1, height: '100%'}} className="App">
      <PlaylistView />
    </div>
  );
}

export default App;
