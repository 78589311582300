export default {
    spotify: {
        clientID: "ca8dfbedd23143169ca9fc9ed083f307",
        clientSecret: "c67209bdcf454434a88c89c8aadf2821"
    },
    firebase: {
        apiKey: "AIzaSyD4Bce2NTRK7Utwj8mcQSBRuPYbC5q6mY0",
        authDomain: "kbox-plah.firebaseapp.com",
        databaseURL: "https://kbox-plah.firebaseio.com",
        projectId: "kbox-plah",
        storageBucket: "kbox-plah.appspot.com",
        messagingSenderId: "233234657858",
        appId: "1:233234657858:web:857c737c21943f4e"
    }
}