import { HttpClient } from "../HttpClient";

export class SpotifyBase extends HttpClient{
    accountURI = 'https://accounts.spotify.com/';
    apiURI = 'https://api.spotify.com/v1/';
    token = null;
    constructor(token=null){
        super();
        this.token = token;
    }

    setToken(token){
        this.token = token;
    }
}