import React from 'react';
import { FirebaseDB, Auth, SearchMusic, Playlist } from '../service';
import SpotifyPlayer from 'react-spotify-web-playback';
import { Button, ProgressBar, Modal } from 'react-bootstrap';
import { Music } from '../components';
import _ from 'lodash';
import * as Storage from '../storage';
import PlaylistSearch from './PlaylistSearch';
import { Settings } from './Settings';
import { PlaylistContext, initialPlaylist } from '../reducer';
import { isMobile } from 'react-device-detect';
import { KboxApi } from '../service/kbox';

const primaryColor = '#00B0F0';

export default class PlaylistView extends React.PureComponent{

    freePlays = 100;

    constructor(props,state){
        super(props,state);
        this.fbdb = new FirebaseDB();
        this.db = this.fbdb.getPlaylistDB();
        this.playedDb = this.fbdb.getPlaylistDBPlayed();
        this.authService = new Auth();
        this.searchService = new SearchMusic();
        this.playlist = new Playlist();
        this.refreshTimeout = null;
        this.browserInterval = null;
        this.trackInterval = null;
        this.browser = null;
        this.player = null;
        this.playlistContext = initialPlaylist;
        this.state = {
            spotifyToken: null,
            userToken: null,
            tracks: [],
            loading: false,
            loadingSpotifyAuth: false,
            spotifyCode: null,
            deviceId: null,
            id: null,
            offset: 0,
            ready: false,
            trackContext: null,
            currentTrack: null,
            nextTrack: null,
            position: 0,
            played: [],
            loadingSong: false,
            updateList: (new Date()).getTime(),
            playlist: undefined,
            playingFrom: 'queue',
            termsAccepted: false,
            user: null,
            premium: false,
            spotifyPremium: true,
            queueClean: false
        }
    }

    listenPlaylist(){
        this.doc.onSnapshot( doc => {
            const data = doc.data();
            let tracks = data.tracks || [];
            const { played } = this.state;


            const premium = data.premium !== false;
            tracks = tracks.slice().filter(t => {
                return !played?.find(p => p.uuid == t.uuid);
            })
            tracks.sort(this.orderTracks);
            const oldTracks = this.state.tracks.slice(0);
            this.setState({
                tracks,
                loading: false,
                ready: true,
                premium,
                updateList: (new Date()).getTime()
            }, () => {
                if(oldTracks.length === 0 && tracks.length > 0)
                    this.skip()
                else if(tracks.length == 0){
                    this.playFromPlaylist()
                }
            })
        })
    }

    skip(){
        const { tracks, nextTrack, currentTrack } = this.state;
        const next = nextTrack && nextTrack != null ? nextTrack : tracks[0] || null;
        if(!next || next === null)
            return;
        if(currentTrack != null){
            const uuid = currentTrack.uuid || "";
            this.playlist.setAsPlayed(this.doc, currentTrack);
            this.setState({
                tracks: this.state.tracks.filter( i => i.uuid != uuid)
            })
        }
        this.setState({
            currentTrack: next,
            position: 0
        },() => {
            this.playNext();
        })
    }

    orderTracks(a,b){
        if(a.order && !b.order){
            return -1;
        }
        if(b.order && !a.order){
            return 1;
        }
        if(a.order && b.order){
            return b.order < a.order ? 1 : -1;
        }
        const aBoost = (a?.likes?.length || 0) + (a?.boost?.length || 0);
        const bBoost = (b?.likes?.length || 0) + (b?.boost?.length || 0);
        if(aBoost > bBoost){
            return -1;
        }
        const dateA = new Date(a.dateAdded.toDate()).getTime();
        const dateB = new Date(b.dateAdded.toDate()).getTime();
        if(a.likes.length === b.likes.length){
            return dateB < dateA ? 1 : -1;
        }
        if(a.likes.length < b.likes.length){
            return 1;
        }
        if(dateB < dateA){
            return 1;
        }
        if(dateA < dateB){
            return -1;
        }
        return 0;
    }

    async playNext(){
        this.setState({
            loadingSong: true
        },() => {
            const { tracks, deviceId, userToken, currentTrack, premium, played } = this.state;
            if(!premium && played && Object.keys(played).length>= this.freePlays){
                return;
            }
            const uri = currentTrack != null ? currentTrack.source.uri : null;
            if(currentTrack != null)
                this.playlist.setOrder(this.doc, currentTrack, 1);
            if(uri === null) {
                return;
            };
            this.setState({
                playingFrom: 'queue'
            })
            fetch(`https://api.spotify.com/v1/me/player/play?device_id=${deviceId}`, {
                method: 'PUT',
                body: JSON.stringify({ uris: [uri] }),
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${userToken}`
                },
            }).then( r => {
                this.setState({
                    loadingSong: false
                })
            }).catch(e => {
                this.playNext();
            })
        })
        
    }

    async playFromPlaylist(){
        const { playlist, deviceId, userToken } = this.state;
        this.setState({
            playingFrom: 'playlist'
        })
        if(playlist && playlist != null){

            await fetch(`https://api.spotify.com/v1/me/player/shuffle?device_id=${deviceId}&state=true`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${userToken}`
                },
            })
            fetch(`https://api.spotify.com/v1/me/player/play?device_id=${deviceId}`, {
                method: 'PUT',
                body: JSON.stringify({ context_uri: playlist.uri }),
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${userToken}`
                },
            })
        }
    }

    getHash(url, key) {
        var matches = url.hash.match(new RegExp(key+'=([^&]*)'));
        return matches ? matches[1] : null;
    }

    listenPlayed(id){
        const doc = this.playedDb.doc(id);
        doc.onSnapshot(d => {
            const data = d.data();
            this.setState({
                played: data?.tracks || []
            })
        })
    }

    async handlePlaylistLogin(){
        const { userToken } = this.state;
        const user = await this.authService.getMe(userToken);
        const { product } = user;
        this.setState({ user })
        this.playlist.getMyPlaylist(user).then(response => {
            if(product != 'premium'){
                this.setState({
                    spotifyPremium: false
                })
                localStorage.clear();
                return;
            }
            if(response){
                this.listenPlayed(response.toString());
                this.doc = this.db.doc(response.toString());
                this.setState({
                    loading: true,
                    id: response
                },() => {
                    this.playlistContext.id = response;
                    this.listenPlaylist();
                })
                
            }
        })
    }

    getUserToken(token=null){
        return this.state.userToken === null ? token : this.state.userToken;
    }

    initializePlayer(){
        const player = this.player;
        if(player === null) return;
        player.addListener('player_state_changed', this.handlePlayerCallback.bind(this));
        player.on('ready', ({ device_id }) => {
            this.handlePlaylistLogin();
            this.setState({
                deviceId: device_id
            }, () => {
                player.togglePlay();
            })
        });
        
        player.connect();
    }

    handlePlaylistSelect(playlist){
        Storage.setPlaylistId(playlist.id);
        this.setState({
            playlist
        },() => {
            if(this.state.playingFrom == 'playlist')
                this.playFromPlaylist()
        })
    }

    handleIframeChange = (e) => {
        let tries = 0;
        this.browserInterval = setInterval(() => {
            const currentUrl = document.getElementById('auth').contentWindow.location.href;
            if(currentUrl == 'about:blank'){
                clearInterval(this.browserInterval);
                return;
            }
            this.applyToken(currentUrl);
            tries++;
        }, 1000);
        if(tries > 10){
            clearInterval(this.browserInterval);
            this.handleSpotifyCode();
        }
    }

    processIframe(){
        const authUrl = this.authService.getSpotifyAuthUrl();
        document.getElementById('auth').src = authUrl;
    }

    applyToken(_url){
        const url = new URL(_url);
        if(window.location.host === url.host){
            const token = this.getHash(url,'access_token');
            if(token != null){
                const expires_in = this.getHash(url,'expires_in');
                const currentToken = this.state.userToken
                this.setState({
                    userToken: token,
                    loadingSpotifyAuth: false
                },() => {
                    this.playlistContext.token = token;
                    if(currentToken === null ){
                        this.getBackgroundPlaylist();
                        this.player = new window.Spotify.Player({
                            name: 'Kbox Player',
                            getOAuthToken: callback => { callback(this.state.userToken) }
                        })
                        this.initializePlayer()
                    }
                })
                Storage.setToken(token,expires_in);
                document.getElementById('auth').src = 'about:blank'
                setTimeout(() => {
                    this.processIframe()
                },  (expires_in* 1000) - 10000)
            }
            if(this.browser && this.browser.close)
                this.browser.close();
            clearInterval(this.browserInterval)
        }
    }

    handleSpotifyCode(){
        const authUrl = this.authService.getSpotifyAuthUrl();
        this.browser = window.open(authUrl,'popUpWindow','height=500,width=500');
        this.browserInterval = setInterval(() => {
            try{
                if(this.browser.closed){
                    clearInterval(this.browserInterval);
                    this.setState({
                        loadingSpotifyAuth: false
                    })
                }
                else if(this.browser.location.href){
                    this.applyToken(this.browser.location.href);
                }
            } catch (e){
                console.log(e);
            }
        },1000)
    }

    componentWillUnmount(){
        clearInterval(this.browserInterval);
        clearTimeout(this.refreshTimeout);
        clearInterval(this.trackInterval);
    }

    componentDidMount(){
        this.trackInterval = setInterval(this.updateTrack.bind(this),1000);
        this.initialize();
    }

    getBackgroundPlaylist(){
        const id = Storage.getPlaylistId();
        if(id && id != null){
            this.searchService.setToken(this.state.userToken);
            this.searchService.getPlaylist(id).then(response => {
                this.setState({
                    playlist: response
                })
            })
        }
    }

    initialize(){
        const auth = Storage.getToken();
        const now = new Date().getTime();
        if(auth != null){
            this.setState({
                userToken: auth.token,
                loadingSpotifyAuth: false
            }, () => {
                this.playlistContext.token = auth.token;
                this.getBackgroundPlaylist();
                this.playerListener();
                let expiration = auth.expires_in - now - 10000;
                if(now > auth.expires_in + 10000){
                    expiration = 900;
                }
                setTimeout(() => {
                    this.processIframe()
                }, expiration)
            })
        }
    }

    playerListener(){
        if(window.Spotify){
            this.player = new window.Spotify.Player({
                name: 'Kbox Player',
                getOAuthToken: callback => { callback(this.state.userToken) }
            })
            this.initializePlayer();
        }
        else {
            setTimeout(this.playerListener.bind(this), 500);
        }
    }

    updateTrack(context=null){
        const { trackContext, tracks, position, currentTrack, nextTrack, loadingSong, played } = this.state;
        if(loadingSong) return;
        if(trackContext === null && tracks.length > 0){
            const _currentTrack = tracks[0];
            this.setState({
                currentTrack: _currentTrack,
                position: 0
            },() => {
                this.playNext();
            })
        } else if(trackContext != null) {
            const currentPosition = context != null ? context.position : position + 1000;
            if((currentPosition) > trackContext.duration || (context != null && context.position === 0 && (currentPosition + 4000) > trackContext.duration)){
                const uuid = currentTrack != null ? currentTrack.uuid || '' : ''
                this.playlist.setAsPlayed(this.doc, currentTrack);
                this.setState({
                    currentTrack: nextTrack,
                    position: 0,
                    tracks: this.state.tracks.filter( i => i.uuid != uuid)
                },() => {
                    this.playNext();
                })
            } else {
                this.setState({
                    position: trackContext.paused? this.state.position : currentPosition
                }, () => {
                    if(this.state.position > 0 && trackContext.paused && trackContext.position === 0){
                        const uuid = currentTrack != null ? currentTrack.uuid || '' : ''
                        this.playlist.setAsPlayed(this.doc, currentTrack);
                        this.setState({
                            currentTrack: nextTrack,
                            position: 0,
                            tracks: this.state.tracks.filter( i => i.uuid != uuid)
                        },() => {
                            this.playNext();
                        })
                    }
                })
            }
            const _tracks = tracks.filter(t => !played?.find(p => p.uuid == t.uuid));
            if(nextTrack != null && played && played != null && played?.find(p => p.uuid == nextTrack?.uuid)){
                this.setState({
                    nextTrack: _tracks[1] || null
                }, () => {
                    if(this.state.nextTrack != null)
                        this.playlist.setOrder(this.doc, this.state.nextTrack, 2);
                })
            } else if(currentTrack != null && (nextTrack === null || nextTrack.uuid === currentTrack.uuid) && (!_tracks[1]  || (_tracks[1].uuid != currentTrack.uuid ))){
                this.setState({
                    nextTrack: _tracks[1] || null
                }, () => {
                    if(this.state.nextTrack != null)
                        this.playlist.setOrder(this.doc, this.state.nextTrack, 2);
                })
            }
        }
    }

    handlePlayerCallback(e){
        this.setState({
            trackContext: e
        }, () => {
            this.updateTrack(e);
        })
    }

    renderLoginButton(){
        const { loadingSpotifyAuth, termsAccepted } = this.state;
        const disabled = loadingSpotifyAuth || !termsAccepted
        return(
            <div
                style={{
                    padding: 20,
                    display: 'flex',
                    flexDirection: 'column',
                    flex: 1,
                    justifyContent: 'center',
                    alignItems: 'center'
                }}
            >
                <div>
                    <img src='/images/logo.png' width='100' height='100' />
                </div>
                <div style={{ marginTop: 10}}>
                    <h1 style={{color:'#fff'}}>KBOX PLAYER</h1>
                </div>
                <div>
                    <p className={'subtitle'}>
                        Para ser um KBOX PLAYER você precisa<br />
                        <span>ter conta premium no Spotify.</span><br />
                        Contas gratuitas não funcionam
                    </p>
                </div>
                <div class={'terms-area'}>
                    <input 
                        type={'checkbox'} 
                        checked={termsAccepted} 
                        id={'check-terms'} 
                        name={'check-terms'} 
                        onChange={() => { this.setState({ termsAccepted: !termsAccepted })}} 
                    />
                    <label for={'check-terms'}>Tenho uma conta premium no SPOTIFY</label>
                </div>
                <Button 
                    size="lg"
                    onClick={this.handleSpotifyCode.bind(this)}
                    disabled={disabled}
                >
                    <i class="fab fa-spotify"></i>{' '}CONECTAR SPOTIFY PREMIUM
                </Button>
                <div className={'terms'}>
                    <span>* Ao continuar, você concorda com os nossos <a onClick={() => { window.open('https://www.kbox.pro/termos-de-uso/','_blank')}} className={'terms-link'} href="javascript:">termos de uso</a></span>
                </div>
            </div>
        )
    }

    renderCenter(){
        return(
            <>
            {this.state.userToken === null ? 
                this.renderLoginButton(): 
                this.renderPlayer()
            }
            </>
        )
    }

    getCurrentSong(){
        return this.state.tracks[this.state.offset || 0];
    }

    togglePlay(){
        const player = this.player;
        if(player === null) return;
        player.togglePlay();
    }

    playlistNext(){
        const player = this.player;
        if(player === null) return;
        player.nextTrack();
    }

    playlistBack(){
        const player = this.player;
        if(player === null) return;
        player.previousTrack();
    }

    clearQueue(){
        this.setState({
            queueClean: false
        })
        const service = new KboxApi(this.state.userToken);
        service.clearQueue().then(console.log).catch(console.log);
    }

    getPlaylistSong(){
        const trackContext = this.state.trackContext != null ? this.state.trackContext : {} ;
        const trackWindow = trackContext.track_window || {};
        const source = trackWindow.current_track || null;
        if(source != null){
            return { source }
        }
        return null;
    }

    renderFreeTierHeader(){
        const { premium, played, ready } = this.state;
        const playedCount =played?.length || 0;
        const left = playedCount >= this.freePlays ? 0 : this.freePlays - playedCount;
        if(!premium && ready)
            return(
                <>
                    <div className={'free-tier-head'}>
                        <i class="fas fa-exclamation-triangle fa-2x"></i>
                        <strong className={'qty'}>{left}</strong> MÚSICAS RESTANTES <span>•</span> <strong><a target={'_blank'} href={'https://wa.me/message/FXXUCX4LGP4RD1'}>ATIVAR GRATUITAMENTE</a></strong>
                    </div>
                    <Modal show={left <= 0}>
                        <Modal.Body className={'register-modal'}>
                            <img src='/images/logo.png' width='60' height='60' />
                            <div className={'register-content'}>
                                <strong className={'register-title'}>ATIVE SEU KBOX PLAYER, É GRÁTIS</strong>
                                <strong className={'register-subtitle'}>SEU PERÍODO DE TESTES TERMINOU =(</strong>
                                <span>Esperamos que você tenha amado o KBOX tanto quanto nós</span>
                                <p> 
                                    Para continuar usando o KBOX PLAYER você precisa ativá-lo. Não se preocupe, é grátis.
                                </p>
                                <div className={'register-action-button'}>
                                    <Button onClick={() => { window.open('https://wa.me/message/FXXUCX4LGP4RD1', '_blank') }}>CONTINUAR USANDO KBOX GRATUITAMENTE</Button>
                                </div>
                                <div className={'register-footer'}>
                                    <p>
                                    Alguma dúvida?<br />
                                    <strong onClick={this.handleContactClick}>ENTRAR EM CONTATO</strong>
                                    </p>
                                </div>
                            </div>
                        </Modal.Body>
                    </Modal>
                </>
            )
    }

    renderPlayer(){
        const id = this.state.id != null ? this.state.id : ''; 
        const { nextTrack, currentTrack, tracks, position, trackContext, playlist, userToken } = this.state;
        const now = currentTrack != null ? (position * 100) / currentTrack.source.duration_ms : 0;
        const _tracks = tracks.filter( track => {
            if(currentTrack != null && track.uuid === currentTrack.uuid)
                return false;
            if(nextTrack != null && track.uuid === nextTrack.uuid)
                return false;
            return true;
        })
        return(
            <div>
                <iframe id="auth" onLoad={this.handleIframeChange} />
            {this.renderFreeTierHeader()}
            <div style={{margin:50}}>
                <div style={{flexDirection:'row', display: 'flex'}}>
                    <div style={{
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        flex:1,
                        display: 'flex'
                    }}>
                        <img src='/images/logo.png' width='60' height='60' />
                        <span style={{fontWeight: 'bold', color:'#fff', fontSize: 24, marginLeft: 15}}>KBOX PLAYER</span>
                    </div>
                    <PlaylistSearch 
                        token={this.state.userToken}
                        onSelect={this.handlePlaylistSelect.bind(this)}
                    />
                    <div
                        style={{
                            flexDirection: 'row',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flex: 1
                        }}
                    >
                        {id != '' &&
                            <div style={{ 
                                borderColor: primaryColor,
                                borderWidth: 4, 
                                borderRadius: 20, 
                                paddingRight: 10, 
                                paddingLeft: 20,
                                paddingBottom: 5,
                                borderStyle: 'solid'
                            }}>
                                <strong style={{ 
                                    fontSize: 45, 
                                    fontWeight: 'bolder', 
                                    letterSpacing: 10, 
                                    color: '#fff'
                                }}>
                                    {id}
                                </strong>
                            </div>
                        }
                    </div>
                </div>
                {playlist && playlist != null && 
                    <div
                        style={{
                            flexDirection: 'row',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                    >
                        <strong style={{color: '#fff', fontSize: 16 }}>Playlist em segundo plano:</strong>
                        {playlist.images &&
                            <img src={playlist.images[playlist.images.length-1].url} style={{ width: 25, marginRight: 10, marginLeft: 10 }} />
                        }
                        <span style={{ color: '#fff', fontSize: 16, fontWeight: 200 }}>{playlist.name}</span>
                    </div>
                }
                <div>

                </div>
                {this.state.playingFrom == 'playlist' &&
                    <div style={{marginTop: 30, marginLeft: 80}}>
                        <Music
                            song={this.getPlaylistSong()}
                        />
                        <a href='javascript:' onClick={this.playlistBack.bind(this)}><i style={{padding: 10}} className={`fas fa-step-backward`} /></a>
                        <a href='javascript:' onClick={this.togglePlay.bind(this)}><i style={{padding: 10}} className={`fas fa-${trackContext != null && trackContext.paused ? 'play' : 'pause'}`} /></a>
                        <a href='javascript:' onClick={this.playlistNext.bind(this)}><i style={{padding: 10}} className={`fas fa-step-forward`} /></a>
                        <Settings />
                    </div>
                }
                {this.state.playingFrom == 'queue' && (this.state.ready && this.state.tracks.length > 0) &&
                    <div style={{marginTop: 30, marginLeft: 80}}>
                        <div>
                            <Music playing song={currentTrack} />
                            <div style={{marginTop: 20, flexDirection: 'row', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                {trackContext && trackContext != null &&
                                <>
                                    <a href='javascript:' onClick={this.togglePlay.bind(this)}><i style={{padding: 10}} className={`fas fa-${trackContext.paused ? 'play' : 'pause'}`} /></a>
                                    <a href='javascript:' onClick={this.skip.bind(this)}><i style={{padding: 10}} className={`fas fa-step-forward`} /></a>
                                    <Settings />
                                </>
                                }
                                <ProgressBar style={{flex:1}} animated  now={now > 100 ? 100 : now} />
                            </div>
                        </div>
                        <a style={{ marginBottom: 20, textDecoration: 'underline'}} title='limpar playlist' href='javascript:' onClick={() => { this.setState({ queueClean: true })}}><i className={`fas fa-ban`} /> LIMPAR FILA</a>
                        <h4 style={{color: '#fff', marginTop: 10 }}>PRÓXIMA MÚSICA</h4>
                        <div style={{ marginBottom: 20, marginTop: 10 }}>
                            <Music song={nextTrack} />
                        </div>
                        <h4 style={{color: '#fff'}}>FILA</h4>
                        <div style={{ marginBottom: 20, marginTop: 10 }}>
                            <div style={{ flex: 1 }} key={this.state.updateList}>
                                {_tracks.map(track => {
                                    return <Music key={track.uuid} song={track} />
                                })}
                            </div>
                        </div>
                    </div>
                }
            </div>
            </div>
        );
    }

    renderContent(){
        const id = this.state.id != null ? this.state.id : '';
        return(
            <article style={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'stretch'
            }}>
                <main style={{ overflowY: this.state.userToken !== null ? 'scroll' : 'display' }}>
                    {this.renderCenter()}
                </main>
                {this.state.userToken !== null && 
                    <div style={{ position: 'fixed', bottom: 0, right: 20 }}>
                        <img width="300" src={'/images/from-cativa.png'} />
                    </div>
                }
            </article>
        )
    }

    render(){
        return (
            <PlaylistContext.Provider value={this.playlistContext}>
                {this.renderContent()}
                {this.renderMobileCheck()}
                {this.renderQueueClean()}
                {!this.state.spotifyPremium && this.renderNonPremium()}
            </PlaylistContext.Provider>
        )
    }

    handleContactClick(){
        window.open('https://api.whatsapp.com/send?phone=5521996163949','_blank')
    }

    handleSpotifyPremiumClick(){
        window.open('https://www.spotify.com/br/premium/','_blank')
    }

    renderNonPremium(){
        return(
            <Modal show>
                <Modal.Body>
                    <img height="150" src={'/images/non-premium.jpg'} />
                    <p>
                        <br />
                        OPS, SEU SPOTIFY <strong>NÃO É PREMIUM</strong><br />
                        <strong>PARA USAR O KBOX PLAYER, VOCÊ PRECISA DE UMA CONTA PREMIUM</strong>
                        <br style={{ marginBottom: 5 }} />
                    </p>
                    <Button onClick={this.handleSpotifyPremiumClick} variant={'primary'}>ASSINAR SPOTIFY PREMIUM</Button>
                    <br />
                    <p>
                        <span style={{ fontWeight: 400, fontSize: 12 }}>Alguma dúvida?</span>
                        <br />
                        <a style={{ color: primaryColor }} href='https://wa.me/message/FXXUCX4LGP4RD' target='_blank'>ENTRAR EM CONTATO</a>
                    </p>
                </Modal.Body>
            </Modal>
        )
    }

    handleQueueClose(){
        this.setState({
            queueClean: false
        })
    }

    renderQueueClean(){
        return(
            <Modal show={this.state.queueClean} onHide={this.handleQueueClose.bind(this)}>
                <Modal.Header closeButton>
                <Modal.Title>Limpar fila</Modal.Title>
                </Modal.Header>
                <Modal.Body>Tem certeza que deseja limpar a fila? Essa ação não pode ser desfeita</Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={this.handleQueueClose.bind(this)}>
                CANCELAR
                </Button>
                <Button variant="primary" onClick={this.clearQueue.bind(this)}>
                LIMPAR A FILA
                </Button>
                </Modal.Footer>
            </Modal>
        )
    }

    renderMobileCheck(){
        if(isMobile){
            return(
                <Modal show>
                    <Modal.Body>
                        <i class="far fa-clock fa-4x"></i>
                        <p>
                            <br />
                            EM BREVE<br />
                            <strong>KBOX PLAYER EM DISPOSITIVO MÓVEL</strong><br />
                            Por enquanto você pode usar normalmente pelo seu computador<br style={{ marginBottom: 5 }} />
                            Para saber mais, entre em contato
                        </p>
                        <Button onClick={this.handleContactClick} variant={'secondary'}>ENTRAR EM CONTATO</Button>
                    </Modal.Body>
                </Modal>
            )
        }
    }


}