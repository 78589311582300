import React, { useEffect, useState, useContext } from 'react';
import { Modal, Button, OverlayTrigger, Accordion, Card, Tooltip, Nav, Navbar, Form, FormControl } from 'react-bootstrap';
import { Search } from '../service/spotify/Search';
import { PlaylistContext } from '../reducer';
import { Playlist } from '../service';
import Genres from '../service/spotify/Genres';

export const Settings = () => {

    const playlistDB = new Playlist();

    const context = useContext(PlaylistContext);
    let { settings } = context;
    const [genres, setGenres] = useState([]);
    const [blocked, setBlocked] = useState(settings.blockedGenres);
    const [search, setSearch] = useState(``);
    const [explicit, setExplicit] = useState(settings.allowExplicit);
    const [show, setShow] = useState(false);

    const foundGenres = genres.filter( g => {
        if(search == `` || search.length < 3)
            return false;
        return !!~g.indexOf(search)
    })

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const blockAll = () => setBlocked(foundGenres);
    const blockNone = () => {
        const gs = blocked.filter(b => {
            return !foundGenres.includes(b)
        });
        console.log(gs);
        setBlocked(gs);
    }

    const unblockAll = () => setBlocked([]);
    
    const toggleGenre = (genre) => {
        if(blocked.includes(genre))
            setBlocked(blocked.filter(b => b != genre))
        else 
            setBlocked([
                ...blocked,
                genre
            ])
    }

    const handleSave = () => {
        settings.blockedGenres = blocked;
        settings.allowExplicit = explicit;
        playlistDB.setConfiguration(context.id, {
            blockedGenres: blocked,
            allowExplicit: explicit
        })
        handleClose();
    }

    const handleCancel = () => {
        setBlocked(settings.blockedGenres);
        setExplicit(settings.allowExplicit);
        handleClose();
    }

    useEffect(() => {
        
        const fetchData = async () => {
            const gs = await Genres();
            setGenres(gs);
            const configuration = await playlistDB.getConfiguration(context.id);
            if(configuration != null){
                settings = configuration.settings;
                setBlocked(settings.blockedGenres);
                setExplicit(settings.allowExplicit);
            }
        }

        fetchData();

    }, [context.token])

    return(
        <>
            <a href='javascript:' onClick={handleShow} ><i style={{padding: 10, color: 'white' }} className={`fas fa-cogs`} /></a>
            <Modal size="lg" show={show} onHide={handleClose} className="my-modal">
                <Modal.Header closeButton>
                <Modal.Title>Configurações</Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    <Accordion style={{ width: '100%' }} defaultActiveKey="0">
                        <Card>
                            <Card.Header>
                            <Accordion.Toggle as={Card.Header} variant="link" eventKey="0">
                                Ajustes
                            </Accordion.Toggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="0">
                                <Card.Body>
                                    <Form>
                                        <Form.Check
                                            label={`Permitir conteúdo explícito`}
                                            id={`teste`}
                                            checked={explicit}
                                            onChange={() => { setExplicit(!explicit) }}
                                        />
                                    </Form>
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                        <Card>
                            <Card.Header>
                            <Accordion.Toggle as={Card.Header} variant="link" eventKey="1">
                                Filtro de Gênero
                            </Accordion.Toggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="1"  style={{ maxHeight: '60vh', overflowX: `scroll` }}>
                                <Card.Body style={{ flexDirection: 'row', display: 'flex' }}>
                                <div style={{ flex: 1 }}>
                                    <Navbar bg="light" expand="lg">
                                        <Navbar.Toggle aria-controls="basic-navbar-nav" />
                                        <Navbar.Collapse id="basic-navbar-nav">
                                            <div>
                                                <div>
                                                    <Form inline>
                                                        <FormControl onChange={e => { setSearch(e.target.value) }} value={search} type="text" placeholder="Buscar" className="mr-sm-2" />
                                                    </Form>
                                                </div>
                                                <div>
                                                    <Nav className="mr-auto">
                                                        <Nav.Link href="javascript:" onClick={blockAll}>Bloquear Todos</Nav.Link>
                                                        <Nav.Link href="javascript:" onClick={blockNone}>Desbloquear Todos</Nav.Link>
                                                    </Nav>
                                                </div>
                                                
                                            </div>
                                        </Navbar.Collapse>
                                    </Navbar>
                                            {foundGenres.map(g => (
                                            <OverlayTrigger key={g} overlay={<Tooltip id="tooltip-disabled">{blocked.includes(g) ? `ligar`: `desligar` }</Tooltip>}>
                                                    <Button style={{ margin: 5 }} onClick={() => toggleGenre(g)} variant={blocked.includes(g) ? `secondary`: `primary`}>
                                                        {g}
                                                    </Button>
                                                </OverlayTrigger>
                                                
                                            ))}
                                </div>
                                {blocked.length > 0 &&
                                <div style={{ flex: 2, backgroundColor: 'rgba(0,0,0,0.1)', border: 'ridge' }}>
                                    <Navbar bg="light" className="flex-column">
                                        <p className="text-center mt-4 mb-4">Gêneros Bloqueados</p>
                                        <Nav className="mr-auto">
                                            <Nav.Link href="javascript:" onClick={unblockAll}>Desbloquear todos os Gêneros</Nav.Link>
                                        </Nav>
                                    </Navbar>
                                    {blocked.map(g => (
                                        <OverlayTrigger key={g} overlay={<Tooltip id="tooltip-disabled">{blocked.includes(g) ? `ligar`: `desligar` }</Tooltip>}>
                                                <Button style={{ margin: 5 }} onClick={() => toggleGenre(g)} variant={blocked.includes(g) ? `secondary`: `primary`}>
                                                    {g}
                                                </Button>
                                            </OverlayTrigger>
                                            
                                        ))}                 
                                </div>
                                }
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    </Accordion>                    
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={handleCancel}>
                    Cancelar
                </Button>
                <Button variant="primary" onClick={handleSave}>
                    Salvar
                </Button>
                </Modal.Footer>
            </Modal>
        </>
    )

}