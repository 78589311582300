import { HttpClient } from "../HttpClient";

export class KboxApi extends HttpClient{
    baseUrl = 'https://api.kbox.pro/';
    token = null;

    constructor(token){
        super();
        this.token = token;
    }

    setToken(token){
        this.token = token;
    }

    clearQueue(){
        return this.getAsync(`${this.baseUrl}queue/clear`)
    }
}