export const TOKEN_EXPIRES = '@kbox/token-expires';
export const USER_TOKEN = '@kbox/user-token';
export const PLAYLIST_ID = '@kbox/playlist-id';

export const setToken = (token, expires) => {
    const now = new Date().getTime();
    localStorage.setItem(USER_TOKEN, token);
    localStorage.setItem(TOKEN_EXPIRES, (expires * 1000) + now);
}

export const getToken = () => {
    const tokenAge = localStorage.getItem(TOKEN_EXPIRES);
    const now = new Date().getTime();
    if(now > tokenAge) {
        return null;
    } else {
        const token = localStorage.getItem(USER_TOKEN);
        return {
            token,
            expires_in: tokenAge
        }
    }
}

export const setPlaylistId = (id) => {
    localStorage.setItem(PLAYLIST_ID, id);
}

export const getPlaylistId = () => {
    const id = localStorage.getItem(PLAYLIST_ID);
    return id || null;
}