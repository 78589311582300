import * as Utils from '../../utils';

export class FirebaseDB {
    constructor(){
        this.playlistDB = Utils.firestore.collection('playlist');
        this.playlistDBPlayed = Utils.firestore.collection('playlist_played');
        this.configurationDB = Utils.firestore.collection('configuration');
    }

    getPlaylistDB(){
        return this.playlistDB;
    }

    getPlaylistDBPlayed(){
        return this.playlistDBPlayed;
    }

    getFirestore(){
        return Utils.firestore;
    }

    getConfigurationDB(){
        return this.configurationDB;
    }

}