import * as Utils from '../../utils';
import { FirebaseDB } from './FirebaseDB';
import { createCipher } from 'crypto';

export class Playlist extends FirebaseDB {

    constructor(){
        super();
        this.db = this.playlistDB;
        this.confDb = this.configurationDB;
    }

    async playlistExists(id,create=false){
        const response = await this.db.doc(id.toString()).get();
        if(response.exists){
            return true;
        } else {
            return false;
        }
    }

    async createPlaylist(user){
        const deviceId = user.id;
        const { birthdate, country, display_name, email, product } = user;
        let id = Utils.newPlaylistNumber();
        const exists = await this.playlistExists(id);
        if(!exists){
            await this.db.doc(id.toString()).set({
                id,
                deviceId, 
                offset: 0,               
                createdAt: new Date(),
                tracks: [],
                premium: false,
                user: {
                    birthdate, country, display_name, email, product
                }
            })
            await this.confDb.add({
                playlist: id.toString(),
                settings: {
                    blockedGenres: [],
                    allowExplicit: true,
                }
            })
        }
        else 
            return this.createPlaylist(user);
        return id;
    }

    updatePlaylist(user,id){
        const { birthdate, country, display_name, email, product } = user;
        this.db.doc(id.toString()).set({ 
            user: {
                birthdate, country, display_name, email, product
            }
        }, { merge: true })
    }

    async getMyPlaylist(user){
        const deviceId = user.id;
        return this.db.where("deviceId","==",deviceId).get().then(doc => {
            if(doc.empty)
                return this.createPlaylist(user)
            const id = doc.docs[0].id;
            this.updatePlaylist(user,id)
            return id;
        })
    }

    async setAsPlayed(doc,track){
        console.log({ doc, track })
        this.getFirestore().runTransaction(transaction => {
            return transaction.get(doc).then(async (snapshot) => {
                const data = snapshot.data();
                const tracks = (data.tracks || []).filter( t => t.uuid != track.uuid);
                
                transaction.update(doc,{ tracks });

                this.getPlaylistDBPlayed().doc(`${data.id}`).get()
                    .then(snapshot => {
                        const playedData = snapshot.data();
                        const playedList  = playedData?.tracks || [];

                        const played = {
                            datePlayed: track?.datePlayed || new Date(),
                            addedBy: track.addedBy.uid,
                            id: track.source.id,
                            likes: track?.likes?.length || track?.boost?.length || 0,
                            uuid: track.uuid
                        };

                        this.getPlaylistDBPlayed().doc(`${data.id}`)
                        .set({ 
                            tracks: [played, ...playedList]
                        })
                })
            })
        }).then(() => {
            this.setOrder(doc,track,null);
            return true;
        }).catch(e =>{
            return false;
        })
    }

    async setOrder(doc,track,order){
        return this.getFirestore().runTransaction(async transaction => {
            return transaction.get(doc).then(async snapshot => {
                const data = snapshot.data();
                let _track = data.tracks.find(t => t.uuid === track.uuid);
                if(_track){
                    _track.order = order;
                    if(order === 1){
                        _track.datePlayed = new Date();
                    }
                    const tracks = data.tracks.map( t => {
                        if(t.uuid === _track.uuid)
                            return _track;
                        return t;
                    })
                    await transaction.update(doc,{tracks})
                    return true;
                } else { 
                    await transaction.update(doc,data)
                    return false;
                }
            }).catch(e => {
                console.log(e);
                this.setOrder(doc, track, order);
            })
        })
    }

    async setOffset(doc,offset){
        this.getFirestore().runTransaction(transaction => {
            return transaction.get(doc).then(_doc => {
                transaction.update(doc,{offset})
            })
        }).then(() => {
            return true;
        }).catch(() =>{
            return false;
        })
    }

    async getConfiguration(playlistID){
        const confDB = this.getConfigurationDB();
        return confDB.where('playlist','==',playlistID.toString()).get().then( response => {
            if(response.empty){
                return null;
            }
            return {
                ...response.docs[0].data(),
                docID: response.docs[0].id
            }
        }).catch(e => {
            return null;
        });
    }

    async setConfiguration(playlistID, settings){
        const configuration = await this.getConfiguration(playlistID);
        const confDB = this.getConfigurationDB();
        if(configuration ==  null){
            return confDB.add({
                playlist: playlistID,
                settings
            })
        }
        return confDB.doc(configuration.docID).set({
            ...configuration,
            settings
        })
    }

    async addPlayed(playlistID){

    }

}

