import config from '../../utils/config';
import * as Base64 from 'base-64';
import { SpotifyBase } from './SpotifyBase';

export class Auth extends SpotifyBase {

    constructor(){
        super();
        this.redirectUri = window.location.href;
    }

    async getSpotifyToken(body=null,grant_type='client_credentials'){
        const { spotify } = config;
        const token = Base64.encode(`${spotify.clientID}:${spotify.clientSecret}`)
        const options = {
            headers: {
                contentType: 'application/x-www-form-urlencoded',
                authorization: `Basic ${token}`
            }
        };
        const url = `${this.accountURI}api/token?grant_type=${grant_type}${body != null ? `&${body}` : ''}`;
        return this.postAsync(url, {}, options);
    }

    getSpotifyAuthUrl(){
        const { spotify } = config;
        const scopes = 'streaming user-read-birthdate user-read-email user-read-private user-read-playback-state user-modify-playback-state';
        return `https://accounts.spotify.com/authorize?response_type=token&client_id=${spotify.clientID}&scope=${encodeURIComponent(scopes)}&redirect_uri=${encodeURIComponent(this.redirectUri)}`;
    }

    getUserToken(){
        const authUrl = this.getSpotifyAuthUrl();
    }

    refreshToken(token){
        return this.getSpotifyToken(`refresh_token=${token}&redirect_uri=${this.redirectUri}`,'refresh_token')
    }

    getMe(token){
        this.setToken(token);
        return this.getAsync(`${this.apiURI}me`);
    }

}