import { SpotifyBase } from "./SpotifyBase";


export class Search extends SpotifyBase{

    async searchTrack(name,limit=20,offset=0){
        const url = `${this.apiURI}search?q=${name}&type=track&market=BR&limit=${limit}&offset=${offset}`;
        return this.getAsync(url);
    }

    async searchPlaylist(name, limit=10, offset=0){
        const url = `${this.apiURI}search?q=${name}&type=playlist&market=BR&limit=${limit}&offset=${offset}`;
        return this.getAsync(url);
    }

    async getPlaylist(id){
        const url = `${this.apiURI}playlists/${id}`;
        return this.getAsync(url);
    }

    async getGenres(){
        const url = `${this.apiURI}recommendations/available-genre-seeds`;
        return this.getAsync(url);
    }
    
}